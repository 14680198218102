import './style.css'
import * as THREE from 'three'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
const axesHelper = new THREE.AxesHelper(1.2)
scene.add(axesHelper)

// Textures
const textureLoader = new THREE.TextureLoader()
const particleTexture = textureLoader.load("/textures/particles/1.png")

// glTF Loader
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('/draco/');

const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);

const material = new THREE.PointsMaterial({
  color: 0xffffff, 
  map: particleTexture,
  size: 0.001,
  depthWrite: false,
  blending: THREE.AdditiveBlending
})

let mesh;

gltfLoader.load(
  '/models/rock-75.glb',
  (gltf) => {
    let model = gltf.scene;
    model.traverse((o) => {
      if (o.isMesh) {
        mesh = new THREE.Points(o.geometry, material)
        //mesh.rotation.x = Math.PI
        mesh.position.y = -1
        scene.add(mesh)
      }
    });
  }
)

// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.01, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2.2
scene.add(camera)
  
// Controls
const controls = new TrackballControls(camera, canvas)
controls.enableDamping = true

// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Animate
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    if (mesh) mesh.rotation.y = 0.1 * elapsedTime;

    // Update controls
    controls.update()
    // Render
    renderer.render(scene, camera)
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()